import styled from 'styled-components';
const S = {};

S.ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.ModalWrapper = styled.div`
    z-index: 99;
    overflow: auto;
    margin: 5rem auto 0 auto;
    background-color: #fff;
    width: ${({width})=> (width!=null) ? width : '51rem' };
    position: relative;
    border-radius: .42rem;
    overflow: visible;
    padding: ${({padding})=> (padding!=null) ? padding : '40px 93px' }}
`;

S.ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

S.ModalTitle = styled.h2`
   margin: 0;
   color: #3c3c3c;
    font-size: 2.83rem;
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;


S.UserIconContainer = styled.div`
    width: 3rem;
    height: 3rem;
`;

S.CloseIconContainer = styled.div`
    position: absolute;
    right: -40px;
    top: -40px;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
    transform: none;
    line-height: 1;
    margin-right: -3rem;
    margin-top: -3rem;
    border-radius: 50%;
    bottom: 0;
    margin: .83rem 0;
    background: #fff url(/images/svg/header_close_black.svg) no-repeat 50% 50%;
    background-size: 1.47rem 1.47rem;
    cursor: pointer;
`;

S.ModalBody = styled.div`
  display: flex;
`;

export default S;
