import typeToReducer from 'type-to-reducer';
import {CHANGE_DATE_BY_TYPE, CHANGE_ACTIVE_TAB, FETCH_TRANSACTIONS_LIST, SET_CURRENT_PAGE, RESET_TO_INITIAL_STATE} from 'accountWalletActions.js';

let initialState = {
    isPending: true,
    error: null,
    startDate: new Date(),
    endDate: new Date(),
    activeTab: 'all',
    data: [],
    currentPage: 0,
    limit: 10
};

const accountWalletInitialState = {...initialState};

export default typeToReducer({
    [CHANGE_DATE_BY_TYPE]: (state, {payload: {dateType, date}}) => {
        return {...state, [dateType]: date}
    },
    [CHANGE_ACTIVE_TAB]: (state, {payload: {activeTab}}) => {
        return {...state, activeTab}
    },
    [SET_CURRENT_PAGE]: (state, {payload: {currentPage}}) => {
        return {...state, currentPage}
    },
    [FETCH_TRANSACTIONS_LIST]: {
        PENDING: (state, action) => {
            return {...state, isPending: true}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {data}}) => {
            return {...state,  isPending: false, data};
        }
    },
    [RESET_TO_INITIAL_STATE]: (state) => {
        return accountWalletInitialState;
    }
}, initialState);
